const projects = [
  {
    title: "DADA - A parenting app",
    image: "https://i.imgur.com/VlZTDbI.png",
    body:
      "A client project to create a parenting app specifically for fathers. Includes user access and authentication, mixed content handling and utilises Firebase for content management.",
    github: "https://github.com/Zdeevo/dada",
    weblink: "https://dada-da4d5.web.app/",
  },
  {
    title: "The Hero's Choice",
    image: "https://i.imgur.com/dqZ26hq.png",
    body:
      "A 'choose your own adventure' game utilising JavaScript switch/case to dynamically assign attributes with each button click to take you through a magical adventure. Are you brave enough?",
    github: "https://github.com/Zdeevo/hero_quest",
    weblink: "https://hero.stevemaguire.dev/",
  },
  {
    title: "Punk API",
    image: "https://i.imgur.com/CGZPKfL.png",
    body:
      "An app to fetch beers from the Punk API. The list of beers can then be filtered according to strength or searched dynamically using a keyword.",
    github: "https://github.com/Zdeevo/punk-beers",
    weblink: "https://punk-beers-c2d48.web.app/",
  },
  {
    title: "Kanye Quote Machine",
    image: "https://i.imgur.com/GRVbrXW.jpg",
    body:
      "Have a chuckle at some of Kanye West's best and most ridiculous quotes, fetched from the Kanye.Rest API using Typescript.",
    github: "https://github.com/Zdeevo/kanye-quote-machine",
    weblink: "https://kanye.stevemaguire.dev/",
  },
  {
    title: "Morse Code Translator",
    image: "https://i.imgur.com/hp8P34K.png",
    body:
      "A morse code translator able to translate english into morse code, and morse code into english. Written in JavaScript.",
    github: "https://github.com/Zdeevo/morse-code-translator",
    weblink: "https://morse.stevemaguire.dev/",
  },
  {
    title: "Calculator",
    image: "https://i.imgur.com/Gqk3WFl.png",
    body:
      "A clean, simple calculator app built using class based JavaScript. One of the first full apps I built during the course.",
    github: "https://github.com/Zdeevo/calculator",
    weblink: "https://calculator.stevemaguire.dev/",
  },
];

export default projects;
